import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Drift } from 'react-live-chat-loader'
import BlogBanner from '../../components/BlogBanner'
import BlogHeader from '../../components/BlogHeader'
import LiveChatLayout from '../../components/LiveChatLayout'
import Seo from '../../components/seo'
import BackArrow from '../../icons/back-arrow.svg'

export default function BlogPost({ data }) {
  return (
    <LiveChatLayout>
      <Seo title="Einführung in die Live-Transkription (in Echtzeit)" />
      <div className="drift-wrapper">
        <Drift icon="B" color="rgb(222, 48, 105)" className="circle" />
      </div>
      <section className="blog-section">
        <div className="blog-content">
          <Link to="/blog" className="back-link">
            <img src={BackArrow} alt="Home" />
          </Link>
          <BlogHeader
            title="Einführung in die Live-Transkription (in Echtzeit)"
            date="1. Oktober 2021"
          />
          <p>
            Der Großteil unseres Arbeitslebens dreht sich um Kommunikation. Wir
            schreiben Besprechungsprotokolle, E-Mails und tauschen Ideen aus.
          </p>
          <p>
            Mit eng gefüllten Terminkalendern und hunderten von Ideen, die im Wettstreit um unsere Aufmerksamkeit und Zeit stehen,
            ist es leicht, wichtige Informationen zu übersehen.
          </p>
          <p>
            Vielen von Ihnen hilft VoxRec bereits mit der Transkription von
            Meetings, E-Mails, Konsultationen und Sprechstunden, Interviews und anderen Aufgaben, wo
            Informationen aufgenommen werden und als Handlungsgrundlage dienen müssen.
          </p>
          <p>
            Manche haben die Sprache-zu-Text-Technologie noch nicht in ihre tägliche
            Kommunikation integriert, was ihren Worfklow behindert, ohne dass sie es
            bemerken.
          </p>
          <p>
            Vür viele Menschen stellt gesprochene Kommunikation eine Herausforderung dar. Menschen mit
            Behinderung, Lese-Rechtschreib-Schwäche oder Dyslexie, und diejenigen, die nicht unsere Muttersprache
            sprechen, haben alle Probleme zu verstehen, was Sie sagen - was
            auf keinen Fall so sein sollte.
          </p>
          <p>
            Heute erweitern wir unseren bestehende Dienstleistung für Sprache zu Text, indem wir
            die Live-Transkription in unsere App einführen. Ihre Stimme wird wie gewöhnlich
            aufgezeichnet, aber die Transkription wird auf dem Bildschirm angezeigt in
            Echtzeit.
          </p>
          <p>
            Wir hoffen, dass dieses Feature unseren Nutzerinnen und Nutzern weiterhin helfen wird, insbesondere
            mit der Inklusion in Umgebungen und Aufgaben, an denen eine Teilhabe ansonsten
            herausfordernd wäre.
          </p>
          <BlogBanner
            title="Sprache-zu-Text-Transkription in Echtzeit"
            text="Sehen und lesen Sie, was gesagt wird, ohne eine dolmetschende Person und ohne Warten. Probieren Sie die Live-Transkription von VoxRec aus."
            withBackground
            picture={
              <StaticImage
                src="../../images/blog/blog-live-transcribe.png"
                className="banner__image"
                alt="VoxRec on iOS"
              />
            }
            appStoreLink={data.site.siteMetadata.appStoreLink}
          />
          <h2>Digitale Barrierefreiheit mit Sprache-zu-Text-Transkription</h2>
          <p>
            Die Weltgesundheitsorganisation schätzt die Anzahl von schwerhörigen
            und gehörtlosen Menschen global auf etwa 466 Millionen.
          </p>
          <p>
            Regierungen treiben jetzt mehr denn je die Gleichheit aller
            voran. Apps für Live-Transkription in Echtzeit können diese Vision zum Leben
            erwecken.
          </p>
          <p>
            Es gibt Millionen von anderen Menschen, denen das Tippen oder Schreiben von Notizen
            schwerfällt. Ob Dyslexie, Dysgraphie, Traumata, oder andere
            Behinderungen die Handbewegungen einschränken oder die Kognition betreffen, Live-
            Transkription kann diesen Menschen helfen, teilzuhaben an Online- oder
            Offline-Konversationen, Meetings und Events.
          </p>
          <h2>Live-Transkription in Internationalen Meetings und Konferenzen</h2>
          <p>
            Wir leben in einer Zeit, in der für Besprechungen und Konferenzen Menschen aus
            unterschiedlichen Ländern zusammenkommen. Live-Transkription hilft professionellen Nutzerinnen
            und Nutzern auf internationalen Tagungen, zu hören und sehen, was
            diskutiert wird. Dies stellt sicher, dass der Fokus auf den Themen und Ideen liegt statt
            darauf, die Sprache der anderen zu verstehen.
          </p>
          <h2>Echtzeit-Untertitel in Kursen und Vorlesungen</h2>
          <p>
            Dozentinnen und Dozenten sowie Studierende könnten viel effizienter arbeiten, wenn sie
            KI-gestützte Spracherkennung in ihre
            Lerneinheiten integrieren würden.
          </p>
          <p>
            Studierende mit Behinderungen oder Nicht-Muttersprachlerinnen und -Muttersprachler können
            VoxRec benutzen, um in Echtzeit als Text zu sehen, was die Dozentin oder der Dozent sagt.
            Das hilft ihnen zu lernen, zu verstehen und besser auf den
            Kontext des Vortrags zu reagieren.
          </p>
          <p>
            Nachdem die Lerneinheit vorbei ist, sendet VoxRec ihnen ein akkurates
            Transkript per E-Mail, das sie als Skript zum Lernen verwenden können.
          </p>
          <p>
            Gleichermaßen können Live-Untertitel bei Konferenuen und Vorträgen
            helfen die Worte der redenden Person visuell zu transportieren, was hilfreich ist, wenn
            kein Übersetzer für Gebärdensprache anwesend ist.
          </p>
          <h2>Probieren Sie die Live-Transkription in VoxRec aus</h2>
          <p>
            Sie können die Live-Transkription in der Aufnahme-Ansicht der VoxRec-App
            aktivieren und ausprobieren. Tippen Sie auf das Robotergesicht (🤖),
            sprechen Sie natürlich und sehen Sie zu, wie der Text auf dem Bildschirm erscheint, während Sie
            reden. Magisch!
          </p>
          <p>
            Sie können die Schriftgröße direkt am Bildschirm anpassen, um sicherzustellen, dass der
            Text gut lesbar ist.
          </p>
          <p>
            Wenn Sie die Aufnahme beenden, stehen Ihnen sowohl die Audio als auch das
            Transkript zur Durchsicht und zum Exportieren zur Verfügung.
          </p>
          <p>
            Beachten Sie - die Live-Transkription kann nicht automatisch per E-Mail
            an Sie gesendet werden, wie es bei der regulären Cloud-Transkription
            einer zuvor aufgenommenen Audio der Fall ist.
          </p>
          <p>
            Falls Sie mehr erfahren möchten, sehen Sie sich unser geupdatetes{' '}
            <a href="/faq">FAQ auf unserer Webseite</a> oder in der App an.
          </p>
          <p>
            Lassen Sie uns wissen, was Sie von dem neuen Live-Transkription-Feature halten
            - jedes Feedback ist willkommen!
          </p>
        </div>
      </section>
    </LiveChatLayout>
  )
}

export const data = graphql`
  query {
    site {
      siteMetadata {
        appStoreLink
      }
    }
  }
`
